
import React, { useEffect } from 'react';
import WOW from 'wowjs';
const EnergysabalData = () => {

    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {
        document.title = "Louco | Raya";
        return () => {
            document.title = "";
        };
    }, []);
    return (
        <div className='about byID wow fadeInDown' data-wow-duration='1.5s'>
            <div className='main-container'>
                <div className='container'>

                    <div style={{ padding: '25px 0 ' }}>

                        <p>Energy Sabal is a forward-thinking home electrification marketplace that
                            simplifies the transition to electric living for homeowners. Their platform enables
                            customers to effortlessly plan, purchase, and schedule electrification projects—such as heat pumps, EV chargers,
                            and solar services—all without requiring a single home visit. With a comprehensive virtual home assessment, personalized
                            roadmaps, and detailed, pre-priced project scopes, Energy Sabal tailors its services to meet each homeowner's unique needs.
                        </p>
                    </div>

                    <div style={{ padding: '25px 0 ' }}>
                        <span>
                            Their mission is to make sustainable and healthy living accessible to households worldwide.
                            By streamlining the process of home electrification, Energy Sabal is transforming how homeowners embrace
                            a greener future, ensuring that the switch to electric is easy, efficient, and hassle-free.
                        </span>
                    </div>

                </div>

            </div>
        </div>
    );
}

export default EnergysabalData;
