import React, { useEffect } from 'react';
import './People.css';
import WOW from 'wowjs';
import { Container } from '@mui/material';
import image_1 from '../../until/People/tb9.883bbcff0c3d2244ce2d.jpg';
import image_2 from '../../until/People/hashmi.png';
import image_3 from '../../until/People/tb11.jpeg'
import image_4 from '../../until/People/tb1.982c00e44e6b78765251.jpg'
import palceholder from '../../until/People/placeholder-1-1.webp';
import image_5 from '../../until/People/tb6.eb4cdfc4314e0f394c6d.jpg';
import image_6 from '../../until/People/tb5.597993113344d77c6953.jpg';
import image_7 from '../../until/People/tb3.749907f13ae8d824ed3e.jpg';
import image_8 from '../../until/People/356627555_10229325771159668_5634161143106312453_n.jpg';
import image_9 from '../../until/People/1714762748748.jpeg'
import image_10 from '../../until/People/tb12.jpeg'
import image_11 from '../../until/People/tb8.jpg';
import image_12 from '../../until/People/tb10.jpg';
import image_13 from '../../until/evin.jpeg';
import image_14 from '../../until/Helin.jpeg'
import image_15 from '../../until/Raj.jpeg'
import image_16 from '../../until/People/tb11.bfbfab42c015d0c2b4a4.jpg'
// import

const People = () => {
    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        document.title = "People | Raya";
        return () => {
            document.title = "";
        };
    }, []);
    const cardData = [
        { id: 1, name: "Fahed Jaarah", title: 'Managing Director', imageUrl: `${image_1}` },
        { id: 2, name: '⁠Abdallah Al-Hashmi', title: 'Partner', imageUrl: `${image_2}` },
        { id: 3, name: "⁠Jonas Kotb", title: 'Partner', imageUrl: `${image_3}` },
        { id: 4, name: "Dr. Rolf van Dawen", title: 'Advisor', imageUrl: `${image_4}` },
        { id: 5, name: "Dr. René Rüth ", title: 'Advisor', imageUrl: `${image_5}` },
        { id: 6, name: "⁠ ⁠Dr. Torsten Netzer", title: 'Advisor', imageUrl: `${image_6}` },
        { id: 7, name: "⁠Dr. Caner Aver ", title: 'Advisor', imageUrl: `${image_7}` },
        { id: 8, name: "Yazan Al-Khatib", title: 'Technical Officer', imageUrl: `${image_8}` },
        { id: 9, name: 'Laya Al-Hilf', title: ' Information Officer', imageUrl: `${image_9}` },
        { id: 10, name: "Ensar Albayrak", title: 'Marketing', imageUrl: `${image_10}` },
        { id: 11, name: "Farouk Jaarah ", title: 'Partner', imageUrl: `${image_11}` },
        { id: 12, name: "Haroun Jaarah ", title: 'Partner', imageUrl: `${image_12}` },
        { id: 13, name: "⁠⁠⁠Evin Acar", title: 'Marketing & Communication', imageUrl: `${image_13}` },
        { id: 15, name: "Raj Sonawala ", title: 'Technical Talent', imageUrl: `${image_15}` },
        { id: 14, name: "⁠⁠Helin Acar ", title: 'Marketing & Communication', imageUrl: `${image_14}` },
        
        { id: 16, name: 'Mahmud Hasan', title: 'Technical Talent', imageUrl: `${image_16}` },
    ];
    return (
        <div className='main-people'>

            <Container maxWidth='xl'>
                <div className='wow fadeInDown' data-wow-duration='1.5s' >
                    <h1>SHAPING HISTORY TOGETHER</h1>
                    <div className="card-container" style={{paddingBottom:"70px"}}>
                        {cardData.map((card) => (
                            <div key={card.id} className="card">
                                <img className="card-image" src={card.imageUrl} alt={card.title} />
                                <div className="card-overlay">
                                </div>
                                <div className="card-title">
                                    <h2>{card.name}</h2>
                                    <p>{card.title}</p>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>

            </Container>
        </div>
    );
}

export default People;