import React, { useEffect, useState } from 'react';
import './MenuContent.css';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CloseIcon from '@mui/icons-material/Close';
import WOW from 'wowjs';
import Tabs from './Tabs'
import { useNavigate } from 'react-router';

const MenuContent = ({ setIsMenuOpen, isMenuOpen, setAnimationClass, animationClass }) => {
    const navigate = useNavigate();
    const toggle = () => {
        setAnimationClass('bounceOutUp');
        setTimeout(() => {
            setIsMenuOpen(false);
            setAnimationClass('bounceInDown');
        }, 1000);
    };

    const transferPage = () => {
        setAnimationClass('bounceOutUp');
        setTimeout(() => {
            navigate('/about');
            setIsMenuOpen(false);
            setAnimationClass('bounceInDown');
        }, 1000);
    };

    const peoplePage = () => {
        setAnimationClass('bounceOutUp');
        setTimeout(() => {
            navigate('/people');
            setIsMenuOpen(false);
            setAnimationClass('bounceInDown');
        }, 1000);
    };

    const partnershipsPage = () => {
        setAnimationClass('bounceOutUp');
        setTimeout(() => {
            navigate('/partnerships');
            setIsMenuOpen(false);
            setAnimationClass('bounceInDown');
        }, 1000);
    };

    const historyPage = () => {
        setAnimationClass('bounceOutUp');
        setTimeout(() => {
            navigate('/history');
            setIsMenuOpen(false);
            setAnimationClass('bounceInDown');
        }, 1000);
    };

    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
    }, []);

    return (
        <>
            {isMenuOpen && (
                <>
                    <div className={`body-menu-content option-tab  wow ${animationClass}`} data-wow-duration='2s'>
                        <div>
                            <CloseIcon className='closeIcon' onClick={toggle} />
                            <h1 onClick={transferPage}>About</h1>
                            <h1 onClick={peoplePage}>People</h1>
                            <h1 className='Partnerships-text' onClick={partnershipsPage}>Partnerships</h1>
                        </div>
                        <Tabs setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} setAnimationClass={setAnimationClass} animationClass={animationClass} />
                        <div style={{ padding:'20px'}} className={`body-menu-contentcopy-right wow ${animationClass}`} data-wow-duration='2s'>
                            <p style={{color:"#d6d6d6" , marginBottom:'5px' , fontSize:'12px'}}>Raya Ventures is committed to respecting and protecting your privacy; please contact  <a href="mailto:INFO@RAYA.VC"> info@raya.vc </a> with any questions regarding our privacy practices.
                            </p>
                            <span style={{color:"#d6d6d6" , marginTop:'0px', fontSize:'12px'}}>©️ 2024 Raya Ventures</span>
                        </div>
                    </div>

                </>

            )}
        </>
    );
};

export default MenuContent;
