
import React, { useEffect } from 'react';
import WOW from 'wowjs';
const Louco = () => {

    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {
        document.title = "Louco | Raya";
        return () => {
            document.title = "";
        };
    }, []);
    return (
        <div className='about byID wow fadeInDown' data-wow-duration='1.5s'>
            <div className='main-container'>
                <div className='container'>

                    <div style={{ padding: '25px 0 ' }}>
                        <h2>01. Louco: Revolutionizing the Entertainment Industry by Bringing People Together</h2>
                        <p>In a world increasingly dominated by digital interactions, Louco is on a mission to bridge the gap between
                            the online and offline worlds. Founded by the dynamic Louco Twins, Evîn Acar and Hêlîn Acar, this innovative
                            app is transforming the entertainment industry by focusing on personalized event recommendations. Louco's
                            vision is clear: to create unforgettable moments by bringing people together in real life.</p>
                    </div>


                    <div style={{ padding: '25px 0 ' }}>
                        <h2>02. A Vision Rooted in Connection</h2>
                        <span>
                            Louco was born out of a simple yet profound vision—to use technology not just to connect people online,
                            but to facilitate real-world experiences. In today’s fast-paced world, where much of our social lives are spent behind
                            screens, Louco aims to change the narrative. The app is designed to bring people together by offering personalized event
                            suggestions based on user preferences, making it easier for people to meet, socialize, and create lasting memories
                        </span>

                    </div>

                    <div style={{ padding: '25px 0' }}>
                        <p>
                            The founders, Evîn and Hêlîn Acar, understood the growing need for meaningful,
                            offline connections. Their passion for bringing people together inspired the creation of Louco, a platform that leverages cutting-edge AI to understand user needs and curate events that match their interests. This unique approach sets Louco apart
                            from traditional event apps, making it a first mover in the entertainment industry.
                        </p>
                    </div>

                    <div style={{ padding: '25px 0' }}>
                        <h2>03. First Mover in the Entertainment Industry</h2>
                        <span>
                            What makes Louco stand out is its pioneering approach in the entertainment sector. As the
                            first mover in this space, Louco has the advantage of setting trends and leading the way in
                            personalized event recommendations. The app goes beyond generic event listings
                            by using advanced AI technology to analyze user behavior and preferences. This allows Louco to offer tailor-made suggestions,
                            ensuring that users find events that truly resonate with them.
                        </span>



                    </div>

                    <div style={{ padding: '25px 0' }}>
                        <p>
                            Whether it’s a music festival, a cozy local gathering, or a spontaneous night out, Louco understands
                            that everyone’s idea of fun is different. By focusing on personalization, Louco ensures that users are not
                            just attending events—they are attending events that matter to them. This focus on user-centric
                            experiences has positioned Louco as a game-changer in the entertainment industry.
                        </p>
                    </div>

                    <div style={{ padding: '25px 0' }}>
                        <h2>04.Bridging the Gap: From Online to Offline</h2>
                        <span>
                            n an era where social media dominates, Louco is on a mission to
                            bring people from the online world into real life. The app’s goal is simple yet powerful: to create real-life
                            experiences by facilitating offline connections. By curating events that align with user interests,
                            Louco encourages users to step out of their virtual bubbles and engage with the world around them.
                        </span>
                    </div>

                    <div style={{ padding: '25px 0' }}>

                        <p>
                            This mission is more important than ever, as many people seek to balance their digital
                            lives with meaningful, face-to-face interactions. Louco’s platform makes it easier for
                            people to discover events they might not have known about, meet new people, and create
                            memories that last a lifetime. In doing so, Louco is not just an app—it’s
                            a movement to bring people together in a world that often feels disconnected
                        </p>

                    </div>

                    <div style={{ padding: '25px 0' }}>
                        <h2>05. The Power of AI: Understanding User Needs</h2>
                        <span>
                            One of the key elements behind Louco’s success is its intensive focus on AI. The app uses artificial
                            intelligence to analyze user data, understand their preferences, and predict what types of events they
                            are likely to enjoy. This deep understanding of user needs allows Louco to offer highly personalized event
                            suggestions, making the app a trusted companion for anyone looking for entertainment.
                        </span>
                    </div>

                    <div style={{ padding: '25px 0' }}>
                        <span>
                            By continuously refining its AI capabilities, Louco ensures that its recommendations are
                            always relevant and tailored to individual users. The app's ability to anticipate and meet user
                            desires is what sets it apart from other platforms in the entertainment space. As Evîn and Hêlîn
                            Acar have hinted, there are still plenty of surprises in store as Louco continues to innovate and evolve
                        </span>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Louco;
