
import React, { useEffect } from 'react';
import WOW from 'wowjs';
import './Novus.css'
const Novus = () => {

    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {
        document.title = "Novus | Raya";
        return () => {
            document.title = "";
        };
    }, []);
    return (
        <div className='about byID wow fadeInDown' data-wow-duration='1.5s'>
            <div className='main-container novus'>
                <div className='container'>



                    <div style={{ padding: '25px 0 ' }}>
                        <h2>01. Novus Lab: Crafting Digital Excellence with a Visionary Leadership Team</h2>
                        <p>
                            In today’s competitive digital landscape, Novus Lab stands out as a company dedicated to
                            creating exceptional web and app experiences. With a mission to empower visions and unlock
                            potential, Novus Lab has become a trusted partner for businesses looking to turn their concepts
                            into dynamic, user-centered realities. At the helm of this innovative company is a visionary leadership
                            team that drives its success. Founded by Abdallah Al-Hashmi, Yazan Al-Khatib,
                            and Laya Al-Hilfi, Novus Lab is redefining the software development journey by offering comprehensive solutions that go beyond expectations.
                        </p>

                    </div>




                    <div style={{ padding: '25px 0' }}>
                        <h2>02. A Visionary Founding Team</h2>
                        <span>
                            Novus Lab's success is rooted in the expertise and passion of its founders, each of whom brings a unique skill set to the table.
                        </span>
                    </div>

                    <div className='FoundingTeam'>
                        <div style={{ padding: '10px 0' }}>
                            <h3>02.01  Abdallah Al-Hashmi, Chief Growth Officer: </h3>
                            <span>
                                As the driving force behind Novus Lab’s growth strategy, Abdallah Al-Hashmi ensures
                                that the company is always at the forefront of innovation. With a keen eye for emerging trends and a deep understanding of
                                market dynamics, Abdallah has played a pivotal role in expanding Novus Lab’s reach and ensuring its continued success.                        </span>
                        </div>

                        <div style={{ padding: '10px 0' }}>
                            <h3>02.02  Yazan Al-Khatib, Chief Technology Officer</h3>
                            <span>
                                The technical backbone of Novus Lab, Yazan Al-Khatib leads the company’s
                                technology initiatives with a focus on delivering cutting-edge solutions. His expertise
                                in software development and commitment to innovation ensure that Novus Lab stays ahead of
                                the curve in the fast-paced digital industry. Yazan’s leadership guarantees that every project
                                is built on a solid technological foundation, making Novus
                                Lab a trusted partner for businesses seeking reliable and innovative digital solutions.
                            </span>
                        </div>

                        <div style={{ padding: '10px 0' }}>
                            <h3>02.03 Laya Al-Hilfi, Chief Operational Officer</h3>
                            <p>
                                Behind every successful project at Novus Lab is the operational excellence driven by Laya Al-Hilfi.
                                As Chief Operational Officer, Laya oversees the day-to-day operations, ensuring that projects are
                                delivered on time, within budget, and to the highest standards. Her attention to detail and commitment
                                to quality make Novus Lab’s processes smooth and efficient,
                                allowing the company to consistently exceed client expectations.
                            </p>
                        </div>

                    </div>


                    <div style={{ padding: '25px 0' }}>
                        <h2>03. Empowering Your Vision, Unleashing Potential</h2>
                        <p>
                            Novus Lab doesn’t just create websites and apps; they empower your vision and unlock your potential. The company’s
                            approach is collaborative, working closely with clients to ensure that every project is perfectly aligned with their
                            goals. Whether you need a full end-to-end solution or an addition to your
                            existing capabilities, Novus Lab is there to help you every step of the way.
                        </p>
                    </div>

                    <div style={{ padding: '25px 0' }}>
                        <h2>04. Crafting Digital Excellence</h2>
                        <span>
                            The foundation of Novus Lab lies in its commitment to crafting digital excellence. From the very
                            beginning of the software development journey, the team works diligently to bring your ideas to life. Novus Lab’s
                            process is designed to be an exciting adventure, where each piece of
                            the puzzle is carefully placed to create the platform of your dreams.
                        </span>
                    </div>

                </div>

            </div>
        </div>
    );
}

export default Novus;
