import React, { useEffect } from 'react';
import WOW from 'wowjs';

const Terms = () => {

    useEffect(() => {

        const wow = new WOW.WOW();
        wow.init();
        window.scrollTo(0, 0)

        document.title = "Privacy Policy | Raya";
        return () => {
            document.title = "";
        };

    }, []);


    return (
        <div className='about byID wow fadeInDown' data-wow-duration='1.5s'>
            <div className='main-container novus'>
                <div className='container'>



                    <div style={{ padding: '25px 0 ' }}>
                        <h2>Terms of Use</h2>
                        <h2>Effective Date: January 1, 2019</h2>
                        <h2>01. Acceptance of Terms</h2>
                        <p>
                        By accessing or using the website Raya.vc, you agree to comply with and be bound by these Terms of Use. If you do not
                         agree with these terms, you are not authorized to use this website.
                        </p>
                    </div>




                    <div style={{ padding: '25px 0' }}>
                        <h2>02. Use of the Website</h2>
                       <p>
                       You may use this website for lawful purposes only. You agree not to use 
                       the website in any way that may damage the website, impair its functionality,
                        or interfere with other users’ enjoyment of the site.
                       </p>
                    </div>

             

                    <div style={{ padding: '25px 0' }}>
                        <h2>03. Intellectual Property</h2>
                        <p>
                        All content on this website, including text, graphics, logos, and software, is
                         the property of Raya.vc or its licensors and is protected by intellectual property laws.
                         You may not reproduce, distribute, or create derivative works from this content without prior written permission.
                        </p>
                    </div>




                    <div style={{ padding: '25px 0' }}>
                        <h2>04. User Content</h2>
                       <p>
                       By submitting content to the website, you grant Raya.vc a
                        non-exclusive, royalty-free, perpetual, and worldwide license
                         to use, reproduce, modify, and display such content. You represent 
                         that you own or have the necessary rights to submit the content.
                       </p>
                    </div>



                    <div style={{ padding: '25px 0' }} >
                        <h2>
                            05. Limitation of Liability
                        </h2>
                        <p>
                        Raya.vc is not liable for any damages that may arise from your use of the website or 
                        from any information provided on the website. The website is provided "as is" without warranties of any kind
                        </p>
                    </div>


                    <div style={{ padding: '25px 0' }} >
                        <h2>
                            06.  Links to Third-Party Sites
                        </h2>
                        <p>
                        Raya.vc may contain links to third-party websites. We do not endorse or assume responsibility for any content or 
                        services provided by third-party sites.

                        </p>
                    </div>



                    <div style={{ padding: '25px 0' }} >
                        <h2>
                            07. Termination
                        </h2>
                        <p>
                        We reserve the right to terminate or suspend your access to the website at any time,
                         without notice, for conduct that we believe violates these Terms of Use or is 
                         harmful to other users of the site or us.
                        </p>
                    </div>
                    

                    <div style={{ padding: '25px 0' }} >
                        <h2>
                            08. Changes to Terms
                        </h2>
                        <p>
                        Raya.vc may revise these Terms of Use at any time. Any changes will be posted on this page, and your continued use of 
                        the website constitutes acceptance of the revised terms.
                        </p>
                    </div>


                    <div style={{ padding: '25px 0' }} >
                        <h2>
                            09. Governing Law
                        </h2>
                        <p>
                        These Terms of Use are governed by the laws of Germany. Any disputes arising from these terms or your 
                        use of the website will be subject to the exclusive jurisdiction of the courts in Germany.
                        </p>
                    </div>

                    <div style={{ padding: '25px 0' }} >
                        <h2 style={{marginBottom:'0px'}}>
                        10. Contact Us
                        </h2>
                      <span>
                      For any questions about these Terms of Use, please contact us at:
                      </span>
                      <h3 style={{marginBottom:'0px'}}>Raya.vc</h3>
                    <span>info@raya.vc</span>
                    </div>
                </div>

            </div>
        </div>

    );
};

export default Terms;

