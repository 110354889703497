import React, { useEffect } from 'react';
import './Partnerships.css';
import WOW from 'wowjs';
import { Container } from '@mui/material';
import dhl from '../../until/partships/dhl-removebg-preview.png'

import americanUniversity from '../../until/partships/American University.jpg';
import bsbi from '../../until/partships/BSBI.jpg';
import bangor from '../../until/partships/Bangor.jpg';
import bradford from '../../until/partships/Bradford.jpg';
import cbs from '../../until/partships/CBS.jpg';
import vivaenuLogo from '../../until/partships/vivenu_logo_Logo-removebg-preview.png'
import ticketMaster from '../../until/partships/ticketmaster-5.svg'
import carlBenzSchool from '../../until/partships/Carl Benz School.jpg';
import deMontfort from '../../until/partships/De Montfort.jpg';
import dundee from '../../until/partships/Dundee.jpg';
import eubs from '../../until/partships/EU-BS.jpg';
import eastAnglia from '../../until/partships/East Anglia.jpg'
import eslsca from '../../until/partships/Eslsca.jpg';
import europeanCollege from '../../until/partships/European College for Liberal Studies.jpg';
import fom from '../../until/partships/FOM.jpg';
import gloucestershire from '../../until/partships/Gloucestershire.jpg';
import greenwich from '../../until/partships/Greenwich.jpg';
import iu from '../../until/partships/IU.jpg';
import illinois from '../../until/partships/Illinois.jpg';
import jaocbs from '../../until/partships/JAOCBS.jpg';
import kansasUniversity from '../../until/partships/Kansas university.jpg';
import kansas from '../../until/partships/Kansas.jpg';
import mbs from '../../until/partships/MBS.jpg';
import manchesterMetropolitan from '../../until/partships/Manchester Metroplitan.jpg';
import mckendree from '../../until/partships/Mckendree.png';
import newHaven from '../../until/partships/New Haven.jpg';
import newcastle from '../../until/partships/Newcastle.jpg';
import nexford from '../../until/partships/Nexford.jpg';
import oregon from '../../until/partships/Oregon.jpg';
import queens from '../../until/partships/Queen\'s.jpg';
import ravensbourne from '../../until/partships/Ravensbourne.jpg';
import sanFrancisco from '../../until/partships/San francisco.jpg';
import stirling from '../../until/partships/Stirling.jpg';
import thompson from '../../until/partships/Thompson .jpg';
import ue from '../../until/partships/UE.jpg';
import uic from '../../until/partships/UIC.jpg';
import dayton from '../../until/partships/logo dayton.jpg';
import amzaon from '../../until/partships/amazon.jpeg'
import apple from '../../until/partships/apple.jpeg'
import eventim from '../../until/partships/eventim-removebg-preview.png'
const Partnerships = () => {
    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        document.title = "Partner-ships | Raya";
        return () => {
          document.title = "";
        };
    }, []);

    return (
        <div className="partnershipsPage wow fadeInDown" data-wow-duration="1.5s" style={{ height: '100vh' }}>
            <div className="container-partnerships">
                <h1>SHAPING THE FUTURE, ONE MOMENT AT A TIME</h1>
                
                <div className="grid-image">
                <img src={amzaon} alt='amzaon'/>
                    <img src={apple} alt='apple'/>
                    <img src={dhl} alt='dhl'/>
                    <img src={americanUniversity} alt="American University" />
                    <img src={eventim} alt='eventim'/>
<img src={ticketMaster} alt='ticketMaster' />
                    <img src={vivaenuLogo} alt='vivaenuLogo'/>
                    <img src={bsbi} alt="BSBI" />
           
                    <img src={bangor} alt="Bangor" />
                    <img src={bradford} alt="Bradford" />
                    <img src={cbs} alt="CBS" />
           
                    <img src={carlBenzSchool} alt="Carl Benz School" />
                    <img src={deMontfort} alt="De Montfort" />
                    <img src={dundee} alt="Dundee" />
                    <img src={eubs} alt="EU-BS" />
                    <img src={eastAnglia} alt="East Anglia" />
                    <img src={eslsca} alt="ESLSCA" />
                    <img src={europeanCollege} alt="European College for Liberal Studies" />
                    <img src={fom} alt="FOM" />
                    <img src={gloucestershire} alt="Gloucestershire" />
                    <img src={greenwich} alt="Greenwich" />
                    <img src={iu} alt="IU" />
                    <img src={illinois} alt="Illinois" />
                    <img src={jaocbs} alt="JAOCBS" />
                    <img src={kansasUniversity} alt="Kansas University" />
                    <img src={kansas} alt="Kansas" />
                    <img src={mbs} alt="MBS" />
                    <img src={manchesterMetropolitan} alt="Manchester Metropolitan" />
                    <img src={mckendree} alt="Mckendree" />
                    <img src={newHaven} alt="New Haven" />
                    <img src={newcastle} alt="Newcastle" />
                    <img src={nexford} alt="Nexford" />
                    <img src={oregon} alt="Oregon" />
                    <img src={queens} alt="Queen's" />
                    <img src={ravensbourne} alt="Ravensbourne" />
                    <img src={sanFrancisco} alt="San Francisco" />
                    <img src={stirling} alt="Stirling" />
                    <img src={thompson} alt="Thompson" />
                    <img src={ue} alt="UE" />
                    <img src={uic} alt="UIC" />
                    <img src={dayton} alt="Dayton" />
                 
                </div>          
            </div>
        </div>
    );
};

export default Partnerships;
