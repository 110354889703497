import React, { useEffect, useState } from 'react';
import './MenuContent.css';
import WOW from 'wowjs';
import { useNavigate } from 'react-router';

const Tabs = ({setIsMenuOpen, isMenuOpen ,setAnimationClass, animationClass}) => {
    const navigate = useNavigate();

    const PrivacyPolicyPage = () => {
        setAnimationClass('bounceOutUp');
        setTimeout(() => {
            navigate('/privacyPolicy');
            setIsMenuOpen(false);
            setAnimationClass('bounceInDown');
        }, 1000);
    };

    const TermsPage =()=>{
        setAnimationClass('bounceOutUp');
        setTimeout(() => {
            navigate('/termsAndConditions');
            setIsMenuOpen(false);
            setAnimationClass('bounceInDown');
        }, 1000);
    }


    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
    }, []);

    return (
        <div className='tabs-teams '>
        <p onClick={TermsPage}> Terms  & Conditions </p>
        <p onClick={PrivacyPolicyPage}>Privacy Policy</p>
        </div>
    );
};

export default Tabs;
