import React from 'react';
import './ThirdSection.css';
import { Container } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router';
import logo from '../../until/logo_louco.png';
import cover from '../../until/louco_background.png'
const ThirdSection = () => {
    const navigate = useNavigate();

    
    const transferToPage = ()=>{
        navigate('/Louco');
    }
    return (
        <div className='main-first-section'>
            <Container maxWidth="xl">
                <div className='content'>
                    <div className='text-container'>
                        <img src={logo} style={{width:'100%' , height:'100%' , marginLeft:'-10px'}}  alt='logo not found'/>
                        <h1>IT'S MORE THAN JUST <br/> AN EVENT APP</h1>
                        <h3 style={{marginBottom:'0px'}}>No more endless searches.</h3>
                        <h3 style={{marginTop:'0px'}}>No more missed opportunities.</h3>
                        <ArrowForwardIcon   onClick={transferToPage} style={{fontSize:'22px' , border:'1px solid #000' ,marginTop:'0px' ,  borderRadius:'50%' , padding:'4px' , cursor:'pointer'}}/>
                    </div>
                    <div className='louco-header-img'>
                        <img src={cover} alt="mobile image not found"/>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default ThirdSection;
