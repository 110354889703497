import React from 'react';
import './SecondSection.css';
import { Container } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router';
import cover from '../../until/logos/image (5).png'
import shishaImage from '../../until/shisha-background-removebg-preview.png'

const SecondSection = () => {
    const navigate = useNavigate();
    const transferToPage = () => {
        navigate('/Shishatabak');
    }
    return (
        <div className='main-first-section other'>
            <Container maxWidth="xl">
                <div className='content'>
                   
                <div>
                        <img src={shishaImage} alt="mobile image not found" className='mainImage' />
                    </div>
                    <div className='text-container shisha_text' style={{ width: '100%' }}>
                        <img src={cover} alt='shisha logo not found' className='logoImage'/>
                        <h1 style={{fontSize:'25px'}}>E-COMMERCE REVOLUTIONIZED FOR THE MODERN AGE</h1>
                        <ArrowForwardIcon onClick={transferToPage} style={{ fontSize: '22px', border: '1px solid #000', borderRadius: '50%', marginTop:'10px',  padding: '4px', cursor: 'pointer' }} />
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default SecondSection;
