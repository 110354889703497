import React, { useEffect } from 'react';
import './About.css';
import WOW from 'wowjs';
const About = () => {

    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
        window.scrollTo(0, 0)
    }, []);
    useEffect(() => {
        document.title = "About-us | Raya";
        return () => {
            document.title = "";
        };
    }, []);
    return (
        <div className='about wow fadeInDown' data-wow-duration='1.5s'>
            <div className='main-container' style={{ textAlign: 'center' }}>
                <div className='container'>
                    <h1>Raya Ventures</h1>
                    <p>
                        Raya Ventures is a private family office, dedicated to managing and growing the
                        investments of the family’s Next Generation. Rooted in the values and successes of our
                        founding generation, our mission is to evolve and diversify our portfolio by embracing new and
                        promising opportunities. With a strategic focus on technology, real estate, and capital markets, we
                        aim to drive sustainable growth and create lasting impact for future generations.
                    </p>
                </div>
            </div>


            <div className='main-container' style={{ textAlign: 'center' }}>
                <div className='container'>
                    <h2>Our Heritage and Vision</h2>
                    <span>
                        Founded on the principles of integrity, innovation, and long-term value creation. Our approach
                        combines time-honored wisdom with a dynamic vision for the future, ensuring that our investments
                        reflect both our heritage and our forward-looking ambitions.
                    </span>

                    <p>

                        We understand that the world is rapidly changing, and with it, the landscape of investment
                        opportunities. Our vision is to be at the forefront of this evolution, identifying and capitalizing on trends that
                        will shape the future while maintaining the core values that have always guided us.
                    </p>
                </div>
            </div>

            <div className='main-container' style={{ textAlign: 'center' }}>
                <div className='container'>
                    <h2>Investment Philosophy</h2>
                    <p>
                        At Raya Ventures, our investment philosophy is grounded in a commitment to excellence
                        and diversification. We believe in a balanced approach that leverages the strength
                        of traditional investments while also exploring new and innovative opportunities.
                    </p>
                </div>
            </div>

            <div className='main-container' style={{ textAlign: 'center' }}>
                <div className='container'>
                    <h2>01. Technology</h2>
                    <p>
                        We are passionate about technology's potential to transform industries and improve
                        lives. Our investments in technology span various sectors, including software, artificial
                        intelligence, Educational Technology, and E-Commerce innovation. We seek out companies and
                        initiatives that are driving change,
                        disrupting markets, and o3ering scalable solutions to global and local challenges.
                    </p>
                </div>
            </div>
            <div className='main-container' style={{ textAlign: 'center' }}>
                <div className='container'>
                    <h2>02. Real Estate</h2>
                    <p>
                        Real estate has long been our cornerstone investment strategy. At Raya Ventures, we continue
                        this tradition by focusing on high-quality assets with strong growth potential. Our real estate
                        portfolio includes residential, commercial, and mixed-use properties, strategically located in
                        markets with robust economic fundamentals. We prioritize
                        investments that o3er long-term appreciation and stable cash flows.
                    </p>
                </div>
            </div>

            <div className='main-container' style={{ textAlign: 'center' }}>
                <div className='container'>
                    <h2>03. Capital Markets</h2>
                    <p>
                        Capital markets o3er a wide range of opportunities for growth and wealth preservation.
                        Our approach to capital markets is both disciplined and innovative, incorporating a mix
                        of traditional equities, fixed income, and alternative investments. We emphasize rigorous
                        research, risk management, and strategic asset allocation to ensure that our
                        portfolio is resilient and well- positioned for future growth.
                    </p>
                </div>
            </div>


            <div className='main-container' style={{ textAlign: 'center' }}>
                <div className='container'>
                    <h2>Commitment to Sustainability</h2>
                    <p>
                        Sustainability is at the heart of everything we do at Raya Ventures. We recognize
                        the importance of responsible investing and are committed to making decisions that
                        contribute positively to society and the environment. Whether it's through supporting
                        green technologies, investing in sustainable real estate developments, or promoting ethical
                        business practices,
                        we strive to ensure that our investments align with our commitment to a better future.
                    </p>
                </div>
            </div>


            <div className='main-container' style={{ textAlign: 'center' }}>
                <div className='container'>
                    <h2>Family Values and Legacy</h2>
                    <p>
                        As a family office, our work is deeply personal. We are not just managing
                        assets; we are stewarding a legacy. Raya Ventures is guided by the values
                        that have been passed down through generations—integrity, stewardship, and a
                        commitment to excellence. Our goal is to ensure that these values continue to
                        guide our family’s investments and that the legacy we build today will be one that future generations can be proud of.

                    </p>
                </div>
            </div>
            <div className='main-container' style={{ textAlign: 'center' }}>
                <div className='container'>
                    <h2>Looking Ahead</h2>
                    <p>
                        The world is full of possibilities, and at Raya Ventures, we are excited about the
                        future. We are constantly exploring new opportunities and staying ahead of emerging
                        trends to ensure that we are well-positioned to achieve our long-term goals. As we look ahead,
                        we remain committed to our mission of creating value, embracing innovation,
                        and preserving the legacy of the Raya family for generations to come.
                    </p>
                </div>
            </div>

            <a className='Get_in_Touch' href="mailto:INFO@RAYA.VC">
                <h2>Get in Touch</h2>
            </a>
        </div>

    );
};

export default About;

