import React, { useEffect, useRef, useState } from 'react';
import './HomePage.css';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import fullpage from 'fullpage.js';
import FirstSection from '../FirstSection/FirstSection';
import SecondSection from '../SecondSection/SecondSection';
import ThirdSection from '../ThirdSection/ThirdSection';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import FourthSection from '../FourthSection/FourthSection';
import Energysabal from '../Energysabal/Energysabal';
import { Container } from '@mui/material';
import WOW from 'wowjs';

import myVideo from '../../until/v_2_videos.mp4';

const HomePage = () => {
    const fullpageRef = useRef(null);
    const [isAutoScrolling, setIsAutoScrolling] = useState(true);
    const intervalIdRef = useRef(null);
    const { value } = useParams();
    const location = useLocation();


    useEffect(() => {
        const fullpageInstance = new fullpage('#fullpage', {
            sectionSelector: '.section',
            scrollBar: false,
            scrollingSpeed: 2000,
            touchSensitivity: 15,
            credits: { enabled: false },
            navigation: true,
        });

        fullpageRef.current = fullpageInstance;
        startAutoScroll();

        return () => {
            fullpageInstance.destroy('all');
            clearInterval(intervalIdRef.current);
        };
    }, [isAutoScrolling]);

    const moveNextSection = () => {
        if (!fullpageRef.current) return;

        const currentSectionIndex = fullpageRef.current.getActiveSection().index;
        const totalSections = document.querySelectorAll('.fp-section').length;

        if (currentSectionIndex === totalSections - 1) {
            console.log('Moving to the first section');
            fullpageRef.current.moveTo(1);
        } else if (currentSectionIndex === 0) {
            console.log('Resetting auto-scroll at the first section');
            clearInterval(intervalIdRef.current);
            startAutoScroll();
        } else {
            fullpageRef.current.moveSectionDown();
        }
    };




    const moveToFirstSection = () => {
        // if(value && location.pathname === '/home'){
        //     if (fullpageRef.current) {
        //         fullpageRef.current.moveTo(1);
        //     }
        // }
      
    };

    

    const startAutoScroll = () => {
        intervalIdRef.current = setInterval(() => {
            if (isAutoScrolling) {
                moveNextSection();
            }
        }, 10000);
    };

    useEffect(()=>{
        moveToFirstSection();
    },[location.pathname])
    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
        window.scrollTo(0, 0);
        document.title = "Home | Raya";

        return () => {
            document.title = "";
        };
    }, []);



    return (
        <div id='fullpage'>
            <div className='section'>
                <div className="background">
                    <video
                        data-keepplaying="true"
                        playsInline
                        autoPlay
                        loop
                        muted
                        className="show hero__video hero__background-video hero__height"
                    >
                        <source src={myVideo} type="video/mp4" />
                    </video>
                    <div className="overlay"></div>
                    <div className="centered-text">
                        <h1>GENERATIONS TO COME</h1>
                    </div>
                    <div className="bottom-text">
                        <ArrowDownwardIcon
                            className='arrow'
                            style={{
                                fontSize: '35px',
                                border: '1px solid #fff',
                                marginBottom: '30px',
                                borderRadius: '50%',
                                padding: '3px',
                            }}
                            onClick={moveNextSection}
                        />
                    </div>
                </div>
            </div>

            <Container maxWidth='xl'>
                {[FirstSection, SecondSection, ThirdSection, FourthSection, Energysabal].map((SectionComponent, index) => (
                    <div key={index} className="section" data-percentage="100" data-centered="true">
                        <SectionComponent />
                    </div>
                ))}
            </Container>
        </div>
    );
};

export default HomePage;
