import React from 'react';
import './FourthSection.css';
import { Container } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router';
import logo from '../../until/logos/image (4).png'
import cover from '../../until/logos/image (3).png'
const FourthSection = () => {

    const navigate = useNavigate();
    const transferToPage = () => {
        navigate('/Novus');
    }
    return (
        <div className='main-first-section'>
            <Container maxWidth="xl">
                <div className='content'>
                      <div>
                        <img src={cover} alt="mobile image not found" className='mainImage' />
                    </div> 
                    <div className='text-container novusLabPart' style={{ width: '100%' , }}>
                        <img className='novuslab_image' style={{marginLeft:'-15px'}} src={logo} alt='novus-lab not found' />
                        <h1 style={{ marginBottom: '0px' }} >WORLD-CLASS SOFTWARE DEVELOPMENT HOUSE</h1>
                        <h3 style={{ marginTop: '0px' }}>Blending traditional production expertise with the speed of cutting-edge technology</h3>
                        <ArrowForwardIcon onClick={transferToPage} style={{ fontSize: '22px', border: '1px solid #000', borderRadius: '50%', padding: '4px', cursor: 'pointer' }} />
                    </div>
                   

                </div>
            </Container>
        </div>
    );
}

export default FourthSection;
