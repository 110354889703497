import React from 'react';
import { Container } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router';
import logo from '../../until/energysabal-black.svg';
import cover from '../../until/shutterstock_2206914231.jpg'
const ThirdSection = () => {
    const navigate = useNavigate();

    
    const transferToPage = ()=>{
        navigate('/Energysabal');
    }
    return (
        <div className='main-first-section'>
            <Container maxWidth="xl">
                <div className='content'>
                    <div className='text-container'>
                        <img src={logo} style={{width:'100%' , height:'100%'}}  alt='logo not found'/>
                        <h1>EMPOWERING HOMES WITH  SEAMLESS ELECTRIFICATION</h1>
                        <ArrowForwardIcon   onClick={transferToPage} style={{fontSize:'22px' , border:'1px solid #000' ,marginTop:'15px' ,  borderRadius:'50%' , padding:'4px' , cursor:'pointer'}}/>
                    </div>
                    <div className='header-img'>
                        <img src={cover} alt="mobile image not found"/>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default ThirdSection;
