import React, { useEffect } from 'react';
import WOW from 'wowjs';
// import { useNavigate } from 'react-router';

const PrivacyPolicy = () => {

    useEffect(() => {

        const wow = new WOW.WOW();
        wow.init();
        window.scrollTo(0, 0)

        document.title = "Privacy Policy | Raya";
        return () => {
            document.title = "";
        };

    }, []);


    return (
        <div className='about byID wow fadeInDown' data-wow-duration='1.5s'>
            <div className='main-container novus'>
                <div className='container'>



                    <div style={{ padding: '25px 0 ' }}>
                        <h2>Privacy Policy</h2>
                        <h2>Effective Date: January 1, 2019</h2>
                        <h2>01. Introduction</h2>
                        <span>
                            Welcome to Raya.vc. We are committed to protecting your personal information and your right to
                            privacy. If you have any questions or concerns about our policy or practices regarding your personal
                            information, please contact us at info@raya.vc.
                        </span>
                        <p>
                        This Privacy Policy explains what information we collect, how we use it, and what rights you have in relation to it.

                        </p>
                    </div>




                    <div style={{ padding: '25px 0' }}>
                        <h2>02. Information We Collect</h2>
                        <span>
                            We collect personal information that you voluntarily provide to us when you register on the website, express an interest in obtaining
                            information about us or our products and services, or otherwise contact us.
                        </span>
                        <br/>
 
                        <br/> 

                        <span>The personal information we collect may include:</span>
                    </div>

                    <div className='FoundingTeam'>
                        <div>
                            <h3 style={{ marginBottom: '5px' }}>02.01  Personal Identifiers: </h3>
                            <span style={{ marginTop: '0px' }}>
                                such as your name, email address, phone number, and company name.
                            </span>
                        </div>

                        <div>
                            <h3 style={{ marginBottom: '5px' }}>02.02 Professional Information:</h3>
                            <span style={{ marginTop: '0px' }}>
                                such as your job title and company name.
                            </span>
                        </div>

                        <div>
                            <h3 style={{ marginBottom: '5px' }} >02.03  Usage Data:</h3>
                            <span style={{ marginTop: '0px' }}>
                                such as information about how you use our website, including pages visited, time spent on the site, and other similar data.
                            </span>
                        </div>

                        <div>
                            <h3 style={{ marginBottom: '0px' }}>02.04 Cookies and Tracking Technologies:</h3>
                            <p style={{ marginTop: '5px' }}>
                                We may use cookies, web beacons, and similar tracking technologies to track the activity on our website and hold certain information.
                            </p>
                        </div>




                    </div>


                    <div style={{ padding: '25px 0' }}>
                        <h2>03. How We Use Your Information</h2>
                        <span>
                            We use the information we collect or receive:
                        </span>
                    </div>

                    <div className='FoundingTeam'>
                        <div>
                            <h3 style={{ marginTop: '0px' }}>

                                03.01 To communicate with you regarding inquiries, requests, or questions you submit.

                            </h3>
                        </div>

                        <div>

                            <h3>
                                03.02 To manage your account and provide customer service.
                            </h3>
                        </div>

                        <div>
                            <h3>
                                03.03 To send promotional communications, such as newsletters, if you have opted to receive them.
                            </h3>
                        </div>

                        <div>
                            <h3>
                                03.04 To improve our website and services by analyzing trends and usage data.
                            </h3>
                        </div>


                        <div>
                            <h3>
                                03.05  To comply with legal obligations and to protect our rights and interests.

                            </h3>

                        </div>




                    </div>



                    <div style={{ padding: '25px 0' }}>
                        <h2>04. Sharing Your Information</h2>
                        <span>
                            We do not share your personal information with third parties except in the following circumstances:
                        </span>
                    </div>


                    <div className='FoundingTeam'>
                        <div>
                            <h3 style={{ marginBottom: '5px' }}>04.01   Service Providers: </h3>
                            <span style={{ marginTop: '0px' }}>
                                We may share your information with third-party vendors and service providers who perform services
                                on our behalf, such as hosting and analytics services.
                            </span>
                        </div>

                        <div>
                            <h3 style={{ marginBottom: '5px' }}>04.02   Legal Obligations: </h3>
                            <span style={{ marginTop: '0px' }}>
                                We may disclose your information where we are legally required to do so
                                in order to comply with applicable laws, regulations, or legal processes.
                            </span>
                        </div>

                        <div>
                            <h3 style={{ marginBottom: '5px' }}>04.03    Business Transfers: </h3>
                            <span style={{ marginTop: '0px' }}>
                                In the event of a merger, acquisition, or sale of all or a portion of
                                our assets, your information may be transferred to the acquiring entity.
                            </span>
                        </div>


                    </div>


                    <div style={{ padding: '25px 0' }} >
                        <h2>
                            05. Cookies and Other Tracking Technologies
                        </h2>
                        <p>
                            We use cookies and similar tracking technologies to collect and use personal information
                            about you, including to serve interest-based advertising. For more information
                            about how we use these technologies and how you can manage your preferences, please refer to our Cookie Policy.
                        </p>
                    </div>


                    <div style={{ padding: '25px 0' }} >
                        <h2>
                            06. Data Security
                        </h2>
                        <p>

                            We implement reasonable and appropriate technical and organizational
                            security measures to protect the personal information we collect and process. However,
                            no security measure is completely secure, and we cannot guarantee the security of your information.
                        </p>
                    </div>

                    <div style={{ padding: '25px 0' }} >
                        <h2>
                            07. Your Data Protection Rights
                        </h2>
                        <span>

                        As a resident of Germany or the European Union, you have the following rights 
                        regarding your personal data under the General Data Protection Regulation (GDPR):
                        </span>
                    </div>


                    <div className='FoundingTeam'>
                        <div>
                            <h3 style={{ marginBottom: '5px' }}>07.01   Access: </h3>
                            <span style={{ marginTop: '0px' }}>
                            You may request a copy of the personal information we hold about you.
                            </span>
                        </div>

                        <div>
                            <h3 style={{ marginBottom: '5px' }}>07.02  Correction: </h3>
                            <span style={{ marginTop: '0px' }}>
                            You may request that we correct any inaccuracies in your personal data.
                            </span>
                        </div>

                        <div>
                            <h3 style={{ marginBottom: '5px' }}>07.03    Deletion: </h3>
                            <span style={{ marginTop: '0px' }}>
                            You may request that we delete your personal information.
                            </span>
                        </div>

                        <div>
                            <h3 style={{ marginBottom: '5px' }}>07.04    Objection: </h3>
                            <span style={{ marginTop: '0px' }}>
                            You may object to the processing of your personal data under certain circumstances.
                            </span>
                        </div>


                        <div>
                            <h3 style={{ marginBottom: '5px' }}>07.05    Data Portability: </h3>
                            <span style={{ marginTop: '0px' }}>
                            You may request that we transfer your data to another controller.
                            </span>
                        </div>


                        <div>
                            <h3 style={{ marginBottom: '5px' }}>07.06    Restriction of Processing: </h3>
                            <span style={{ marginTop: '0px' }}>
                            You may object to the processing of your personal data under certain circumstances.
                            </span>
                        </div>



                        <div>
                            <h3 style={{ marginBottom: '5px' }}>07.07    Right to Withdraw Consent: </h3>
                            <span style={{ marginTop: '0px' }}>
                            If we are processing your personal data based on your consent, you have the right to withdraw your consent at any time.
                            </span>
                        </div>

<p>
To exercise any of these rights, please contact us at info@raya.vc. We will respond to your request in accordance with applicable data protection laws.

</p>

                    </div>

                    <div style={{ padding: '25px 0' }} >
                        <h2>
                            08. International Data Transfers
                        </h2>
                        <p>
                        If you are accessing our website from outside Germany, please be aware 
                        that your information may be transferred to, stored, and processed in Germany where 
                        our servers are located and our central database is operated. By using our services, 
                        you consent to this transfer. We will take all necessary measures to
                         ensure that your data is treated securely and in accordance with this Privacy Policy.
                        </p>
                    </div>

                    <div style={{ padding: '25px 0' }} >
                        <h2>
                            09. Changes to This Privacy Policy
                        </h2>
                        <p>
                        We may update this Privacy Policy from time to time in response to changing 
                        legal, technical, or business developments. When we update our Privacy Policy, we
                         will take appropriate measures to inform you, consistent with the significance of the changes we make. We will obtain your consent to any
                         material Privacy Policy changes if and where this is required by applicable data protection laws.
                        </p>
                    </div>

                    <div style={{ padding: '25px 0' }} >
                        <h2 style={{marginBottom:'0px'}}>
                        10. Contact Us
                        </h2>
                      <span>If you have any questions or concerns about this Privacy Policy, please contact us at:</span>
                      <h3 style={{marginBottom:'0px'}}>Raya.vc</h3>
                    <span>info@raya.vc</span>
                    </div>

                


                    {/*  */}

                </div>

            </div>
        </div>

    );
};

export default PrivacyPolicy;

